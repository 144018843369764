


.row-wrapper25 {
    display: flex; 
    display: -webkit-flex;
    display: -ms-flex;
    display: -moz-flex;
  
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;

    margin-left: 10vw;
    margin-right: 10vw;
    padding-right: 0.5vw;
    padding-left: 0.5vw;

    font-size: large;
  }

.kafelek {
    text-align: left;

    flex: 23%;
    -webkit-flex: 23%;
    -ms-flex: 23%;
    -moz-flex: 23%;
  
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    max-width: 23%;

    margin-top: 5%;
    margin-left: 1%;
    margin-right: 1%;

    padding-top: 10%;
    padding-left: 1%;
    padding-right: 1%;
    padding-bottom: 1%;

    border: solid 1px #444;
  
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-size: 100%;
    color: #AAA;

    transition: transform .2s;
  }

  .box2 {
    padding-top: 2%;
    height: 130px;
  }

  .box3 {
    height: 80px;
  }

  .box4 {
    margin-top: 20%;
  }

  .pbutton25 {
    flex: 25%;
    -webkit-flex: 25%;
    -ms-flex: 25%;
    -moz-flex: 25%;
  
    border-radius: 90px;
    -moz-border-radius: 90px;
    -webkit-border-radius: 90px;
    max-width: 25%;

    margin: 1%;

    border: solid 1px #444;

    text-align: center;

    transition: transform .2s;
  }



  .pcolumn_text {

    padding-top: 0;
    padding-left: 1%;
    padding-right: 1%;
  }

   .pcell25 {
    margin-top: 15px;    
    vertical-align: middle;
    width: 100%;
    height: 50%;
  }

  .pcell_ul {
    color: #AAA;
    margin-left: 0;
    padding-left: 1.0vw
  } 

  .kafelek:hover {
    transform: scale(1.1);
  }

  .pbutton25:hover {
    transform: scale(1.1);
  }

  .hline {
    color: #333;
    text-align: center;
    border-top-style: solid;
    border-top-color: rgb(255,255,255, 0.1);
    margin-top: 5%
  }

  @media all and (max-width: 1280px) {
    .row-wrapper25 {
      justify-content: center;
    }

    .kafelek {
      flex: 46%;
      -webkit-flex: 46%;
      -ms-flex: 46%;
      -moz-flex: 46%;
 
      max-width: 46%;

      margin-left: 2%;
      margin-right: 2%;
      margin-top: 2%;
      margin-bottom: 2%;

      padding-top: 20%;
      padding-left: 2%;
      padding-right: 2%;
    }

    .pbutton25 {
      flex: 46%;
      -webkit-flex: 46%;
      -ms-flex: 46%;
      -moz-flex: 46%;
    
      max-width: 46%;
  
      margin: 2%;

    }

    .pcolumn_text {
      padding-top: 0;
      padding-left: 1%;
      padding-right: 1%;
    }

    .pcell_ul {
      padding-left: 2.0vw
    }
 }

 @media all and (max-width: 960px) {
    .kafelek {
      flex: 70%;
      -webkit-flex: 70%;
      -ms-flex: 70%;
      -moz-flex: 70%;
  
      max-width: 70%;

      margin-top: 3%;
      margin-bottom: 3%;
 
      padding-top: 30%;
      padding-bottom: 1%;
      padding-left: 3%;
      padding-right: 3%;
    }

    .pbutton25 {
      flex: 70%;
      -webkit-flex: 70%;
      -ms-flex: 70%;
      -moz-flex: 70%;
    
      max-width: 70%;
  
      margin: 3%;

    }

    .pcolumn_text {
      padding-top: 0;
      padding-left: 1%;
      padding-right: 1%;    }

    .pcell_ul {
     padding-left: 3.0vw;
   }
  }

  @media all and (max-width: 780px) {
    .kafelek {
      flex: 90%;
      -webkit-flex: 90%;
      -ms-flex: 90%;
      -moz-flex: 90%;
  
      max-width: 90%;

      margin-top: 4%;
      margin-bottom: 4%;
 
      padding-top: 40%;
    }

    .pcolumn_text {
      padding-top: 0;
      padding-left: 1%;
      padding-right: 1%;    }
  }