.container-main {
  position: relative;
  min-height: 100vh;
}


.beginning {

  font-family: 'Inter',sans-serif;
  font-weight: 300;

  max-width: 100vw;
  margin-top:10vw; /*the header height*/

  display: flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: -moz-flex;

  justify-content: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;  

  color: white;
}

.heading {

  font-size: 3vw;
  font-family: 'Inter',sans-serif;
  font-weight: 300;
  text-justify: none;

  max-width: 100vw;
  margin-top: 170px; /*the header height*/

  display: flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: -moz-flex; 

  color: white;
}

.title {
  font-size: 3vw;
}
/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
/* @media screen and (min-width: 601px) {
  .title{ font-size: 60px; }
} */

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
 @media screen and (max-width: 600px) {
  .title{ font-size: 5vw; }
  .beginning{ margin-top: 30vw; }  /*the header height*/
  .heading { font-size: 6vw; }
} 

.subtitle {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.1vw;
  padding-top: 0px;
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
  .subtitle{ font-size: 2vw; }
} 

.subtitler {
  font-size: 2vw;
  text-align: right; 

}

.infoframe {
  /* max-width: 200vw; */
  background-color: black;
  color: white;
  
  display: flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: -moz-flex;

  flex-direction: row;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;

  justify-content: center;
  -webkit-justify-content: center;
  -moz-justify-content: center; 

  align-content: center;
  -webkit-align-content: center; 

   align-items: center;
  -webkit-align-items: center;  
  
  margin-left: 5vw;
  margin-right: 5vw;
  padding-right: 0.5vw;
  padding-left: 0.5vw;
  /* padding-bottom: 0.5vh;  */
}


.infotext {
  color: #CCC; 
  background-color:black;

  flex: 1; 
  -webkit-flex: 1;
  -ms-flex: 1;
  -moz-flex: 1;
 
   justify-content: center; 
  -webkit-justify-content: center;
  -moz-justify-content: center;   
}

.infotext .icons {
  color: white;
}

.infotext .icons img {
  width: 40px;
}

.imgbutton {
  display: flex; 
  display: -webkit-flex;
  display: -ms-flex;
  display: -moz-flex;

  justify-content: space-between; 
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;  

  color: white;
}

.imgbutton img {

  max-width: 250px;
  max-height: auto; 

  transition: transform .2s; /* Animation */
}

.imgbutton img:hover {
  transform: scale(1.1);
}

.infoframe a:link {
  color:burlywood;
}

.infoframe a:hover {
  color: hotpink;
}


.infoframe img {
  vertical-align: middle;
  width: 100%; 
}

.linkimg img {
  transition: transform .2s; /* Animation */
}

.linkimg img:hover {
  transform: scale(1.1);
}

.infoframe video {

  min-width: 60vw;
  max-height: 60vh;       

  object-fit: scale-down;  
  
}


.fullscreen {

  display: flex;

  max-width: 100vw;
  max-height: auto; 


   justify-content: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;    


  overflow: hidden;
  
}

.fullscreen video {

  max-width: 100vw;
  max-height: auto;

  object-fit: scale-down; 

}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
}
		
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
 
.button {
  background-color:black;
  color: white;
  padding: 15px 55px;

  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;

  text-align: center;
  font-size: 16px;
  font-weight: bold; 
  margin: 1px;
  cursor: pointer;
  border: none;
}


/*any page*/





/*Gallery*/
.row {
 margin: 6vw;
}

.rowbtn {
  margin: 0vw;
}


/* Add padding BETWEEN each column (if you want) */
.row,
.row > .column {
  padding: 8px;
}

/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
}

/* Create four equal columns that floats next to each other */
.column4 {
  float: left;
  width: 25%;
  padding-right: 8px;
}

.pbuttonsel {

  border-radius: 90px;
  -moz-border-radius: 90px;
  -webkit-border-radius: 90px;

  border: solid 1px #444;

  text-align: center;

  padding: 15px;

  transition: transform .2s;

  font-size: 1vw;

}

.pbuttonsel:hover {
  transform: scale(1.1);
}

/* Clear floats after rows */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Content */
.content {
  background-color: black;
  padding: 10px;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 900px) {
  .column {
    width: 100%;
  }
  .pbuttonsel { font-size: 2vw;}
}


.row-wrapper {
  display: flex; 
  display: -webkit-flex;
  display: -ms-flex;
  display: -moz-flex;

   flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap; 

  
  margin-top: 0vh;
  margin-left: 20vw;
  margin-right: 20vw;
  padding-right: 0.5vw;
  padding-left: 0.5vw; 
  padding-bottom: 0.5vh;
  background-color: rgb(27, 27, 28); 
}

.pcolumn {
  flex: 50%;
  -webkit-flex: 50%;
  -ms-flex: 50%;
  -moz-flex: 50%;

  max-width: 50%;
  padding-top: 5px;
  padding-left: 1.5vw;
  padding-right: 1.5vw;

  color: white;
  /* font-size: 1.2em; */
}

.pcolumn img {
  margin-top: 20px;
  vertical-align: middle;
  width: 100%;
}

.pcolumn h4 {
  display: flex;
  justify-content: center;
}

@media only screen and (orientation: landscape) {


   .infotext {
    max-width: 900px;

    justify-content: left;
    -webkit-justify-content: left;
    -moz-justify-content: left; 
    }
    
    
} 

@media all and (max-width: 1224px) {
   .infoframe {

    margin-left: 5vw;
    margin-right: 5vw; 
    padding-right: 0.5vw;
    padding-left: 0.5vw;
  } 
  .row-wrapper {
    margin-left: 5vw;
    margin-right: 5vw; 
    padding-right: 0.5vw;
    padding-left: 0.5vw;
  }
  .pcolumn {
    flex: 100%;
    -webkit-flex: 100%;
    -ms-flex: 100%;
    -moz-flex: 100%;

    max-width: 100vw;
  }
}

