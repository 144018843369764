.footer {
    display: flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: -moz-flex;

    position: absolute;
    bottom: 0;
    width: 100%;
    height: 20.5rem; /* Footer height */
    color: white;
    background-color: rgba(0,0,0,0.5);

    justify-content: center;
    -webkit-justify-content: center;
    -moz-justify-content: center; 

    align-items: center;
    -webkit-align-items: center; 

    font-size: 12pt;

    border-top-style: solid;
    border-top-color: rgb(255,255,255, 0.5);

  }

  .footer a {
      margin: 0 20px;
  }
  
  .ending {
    padding-bottom: 25.5rem;    /* Footer height */
  }

  .ficons {
    flex: 0 1 auto;

    text-align: center;

    justify-content: center;
    -webkit-justify-content: center;
    -moz-justify-content: center; 

    align-items: center;
    -webkit-align-items: center; 
  }
    

  .ficons img {
    vertical-align: middle;
    width: 30px;
  }