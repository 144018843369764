  .row-wrapper25home {
    display: flex; 
    display: -webkit-flex;
    display: -ms-flex;
    display: -moz-flex;
  
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;

    margin-left: 10vw;
    margin-right: 10vw;
    margin-bottom: 5vw;
    padding-right: 0.5vw;
    padding-left: 0.5vw;

    font-size: large;
  }

  .kafelekhome {
    flex: 23%;
    -webkit-flex: 23%;
    -ms-flex: 23%;
    -moz-flex: 23%; 

    margin-top: 5%;
    margin-left: 1%;
    margin-right: 1%;

    padding-top: 0%;
    padding-left: 0%;
    padding-right: 0%;
    padding-bottom: 1%;
  
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    max-width: 23%; 
    
    border: solid 1px #444;  
   
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-size: 100%;
    color: #AAA; 

    transition: transform .2s;
  }

  .kafelekhome:hover {
    transform: scale(1.1);
  }

  .kafelekhome video 
  {
    width: 100%;
    top: 0;
    object-fit:contain;

    border-radius: 10px 10px 0px 0px;
    -moz-border-radius: 10px 10px 0px 0px;
    -webkit-border-radius: 10px 10px 0px 0px;
  } 

  .kafelekhome img 
  {
    width: 100%;
    top: 0;
    object-fit: contain;

    border-radius: 10px 10px 0px 0px;
    -moz-border-radius: 10px 10px 0px 0px;
    -webkit-border-radius: 10px 10px 0px 0px;
  } 

  .box2home {
    padding: 10px;
    text-align: left;
  }
  
 @media all and (max-width: 1280px) {
  .row-wrapper25home {
    justify-content: center;
  }

  .kafelekhome {
    flex: 46%;
    -webkit-flex: 46%;
    -ms-flex: 46%;
    -moz-flex: 46%; 

    max-width: 46%; 
 
    margin-top: 5%;
    margin-left: 1%;
    margin-right: 1%;

    padding-top: 0%;
    padding-left: 0%;
    padding-right: 0%; 
    padding-bottom: 1%;
  }
}
 
  @media all and (max-width: 960px) {
    .kafelekhome {
      flex: 70%;
      -webkit-flex: 70%;
      -ms-flex: 70%;
      -moz-flex: 70%; 
  
      max-width: 70%;

      margin-top: 5%;
      margin-left: 1%;
      margin-right: 1%;
 
      padding-top: 0%;
      padding-left: 0%;
      padding-right: 0%;
      padding-bottom: 1%; 
    }
  }

  @media all and (max-width: 780px) {
    .kafelekhome {
      flex: 90%;
      -webkit-flex: 90%;
      -ms-flex: 90%;
      -moz-flex: 90%; 
  
       max-width: 90%;

       margin-top: 5%;
       margin-left: 1%;
       margin-right: 1%;
 
      padding-top: 0%;
      padding-left: 0%;
      padding-right: 0%;
      padding-bottom: 1%;  
    }
  }