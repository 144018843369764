
body {
  background-color: black;    
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  /* font-family: 'Inter', sans-serif, 'Montserrat';  */
  /* font-family: 'Montserrat', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial;  */
  /* font-style: normal;*/
  font-weight: 400;    
  font-size: large;

  /* src: local('Inter-Light'), url('./fonts/Inter-Light.ttf') format('truetype');
  src: local('Inter-SemiBold'), url('./fonts/Inter-SemiBold.ttf') format('truetype');
   */
  src: url('./fonts/montserrat-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('./fonts/montserrat-v14-latin-800.eot'), url('./fonts/montserrat-v14-latin-600.eot'); ; 
  src: local('Montserrat Regular'), local('Montserrat-Regular'), local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'), local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
       url('./fonts/montserrat-v14-latin-regular.eot?#iefix') format('embedded-opentype'), 
       url('./fonts/montserrat-v14-latin-800.eot?#iefix') format('embedded-opentype'), 
       url('./fonts/montserrat-v14-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/montserrat-v14-latin-regular.woff2') format('woff2'), 
       url('./fonts/montserrat-v14-latin-800.woff2') format('woff2'), 
       url('./fonts/montserrat-v14-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/montserrat-v14-latin-regular.woff') format('woff'), 
       url('./fonts/montserrat-v14-latin-800.woff') format('woff'),
       url('./fonts/montserrat-v14-latin-600.woff') format('woff'), /* Modern Browsers */
       url('./fonts/montserrat-v14-latin-regular.ttf') format('truetype'), 
       url('./fonts/montserrat-v14-latin-800.ttf') format('truetype'), 
       url('./fonts/montserrat-v14-latin-600.ttf') format('truetype'),/* Safari, Android, iOS */
       url('./fonts/montserrat-v14-latin-regular.svg#Montserrat') format('svg'),
       url('./fonts/montserrat-v14-latin-800.svg#Montserrat') format('svg'),
       url('./fonts/montserrat-v14-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
      
/*  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-family: -apple-system, """Oxygen", "Roboto", "BlinkMacSystemFont", "Segoe UI", 
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue""",
  sans-serif;*/  

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  line-height: 200%);  
  text-align: justify; 
}

a {
  color: rgb(126, 228, 247);
  text-decoration: none;
  /* font-weight: 600; */
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} 
