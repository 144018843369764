.Header {
    /* position: fixed;  */
    top: 0;
    left: 0;

    /* z-index: 100; */
    width: 100vw; 
    height: 170px;

    background-color:rgba(0,0,0,1.0); 
    /* background-color:rgba(0,0,0,0.9); */

    display: grid;
   
    grid-template-columns: 0.8fr repeat(1, 256px) 3fr repeat(4, 128px) 0.8fr;

    align-items: center;

    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);  
    -webkit-transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);  
    -moz-transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);  
    -o-transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);  
}

.Header a {
    color: white;
    font-weight: 30; 
}

.topnav {
    color: white;

    display: flex; 
    display: -webkit-flex;
    display: -ms-flex;
    display: -moz-flex;

    justify-content: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;

    align-items: center; 
    -webkit-align-items: center; 
}

.topnav.rightm {
    display: none;
}

.topnav a:hover {
    color: rgb(0, 0, 0);
}

.topnav.logo {
    grid-column: 2;
    padding-left: 10px;  
}

.topnav.logo svg {
    max-width: 300px;
    height: 100px; 
}

.topnav.plotoptix {
    grid-column: 4;

    /* background-image: url("./../../images/blackbox.png"); */

    background-size: 100%;
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%; 

    background-repeat: no-repeat;
    background-position: center;
    max-height: 100%;
    max-width: 100%;
}

.topnav.plotoptix img {
    max-width: 120px;
    height: auto; 
}

.topnav.work {
    grid-column: 5;

    /* background-image: url("./../../images/blackbox.png"); */

    background-size: 100%;
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%; 

    background-repeat: no-repeat;
    background-position: center;
    max-height: 100%;
    max-width: 100%;
}

.topnav.work img {
    max-width: 120px;
    height: auto; 
}

.topnav.about {
    grid-column: 6;
    /* background-image: url("./../../images/blackbox.png"); */

    background-size: 100%;
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;

    background-repeat: no-repeat;
    background-position: center;
    max-height: 100%;
    max-width: 100%;
}

.topnav.about img {
    max-width: 120px;
    height: auto; 
}

.topnav.contact {
    grid-column: 7;
    /* background-image: url("./../../images/blackbox.png"); */

    background-size: 100%;
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;

    background-repeat: no-repeat;
    background-position: center;
    max-height: 100%;
    max-width: 100%;
}

.topnav.contact img {
    max-width: 120px;
    height: auto; 
}

.topnav .hambmenu {
    grid-column: 2;
}

 @media all and (max-width: 1024px) {
    .topnav.work {
        display: none;
    }
    .topnav.plotoptix {
        display: none;
    }
    .topnav.services {
        display: none;
    }
    .topnav.about {
        display: none;
    }
    .topnav.contact {
        display: none;
    }
    .topnav.rightm {
        display:block;
    }

    .Header {
        grid-template-columns: repeat(2, 1fr);
    }


    .topnav.logo {
        grid-column: 1;  
        /* background-color: blueviolet; */
    }

    .topnav.logo svg {
        max-width: 150px;
        height: 100px;   
    }  

    .topnav.rightm {
        grid-column: 2;
        background-color: rgb(107, 93, 88);
    } 

    .topnav.rightm ul {
        list-style: none;
    
        padding: 0;
        margin: 10px; 
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;  
    }
 }  


/* @media all and (min-width: 1025px) {
    .topnav.hambmenu {
        display: none;
    }
}  */

