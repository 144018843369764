
.container-video {  

  color: white;
/*  
  max-height: 100vh; */
  max-height: 50vh;   


  display: flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: -moz-flex;

  align-items: center;
  -webkit-align-items: center;

  justify-content: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;

  flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  -moz-flex: 1;

  overflow: hidden;  /*that is a magic. It masks a picture, video*/
}

.container-video img {
  object-fit: cover; /*centers an image/video*/
}

.container-video video {
  
  /* min-width: 100vw; 
  max-height: 100vh;    */

  max-width: 100vw;
  min-height: 50vh;  

  object-fit: cover; 
}

@media only screen and (orientation: portrait){

  .container-video {

    min-height: 50vh; 
    /* min-height: 100vh;  */
      
  }
}

@media only screen and (orientation: landscape){

  .container-video {

   min-width: 50vw;   
    /* min-width: 100vw;   */

  }
}




/* .container-video {
    position: absolute;  
    top: 0; 
    bottom: 0;   

    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%); 

    z-index: 0;
  }
  
  @media only screen and (orientation: portrait){
  
    .container-video {
  
        max-width: auto;
        max-height: 100%;    
    
        min-width: auto; 
        min-height: 100%;      

        width: auto; 
        height: 100%;     
    }
  }
  
  @media only screen and (orientation: landscape){
  
    .container-video {
  
      max-width: 100%;
      max-height: auto;   

      min-width: 100%; 
      min-height: auto;     

      width: 100%; 
      height: auto;  

    }
  } */