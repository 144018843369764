.webgl {
    display: flex;   
    display: -webkit-flex;
    display: -ms-flex;
    display: -moz-flex; 

    align-items: center;
   -webkit-align-items: center;  

   justify-content: center;
   -webkit-justify-content: center;
   -moz-justify-content: center; 
 
    flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    -moz-flex: 1;  
  

   height: 210px;  
   overflow: hidden; 
}